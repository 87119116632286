var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule" },
    [
      !_vm.showImport
        ? _c(
            "div",
            { attrs: { id: "Schedule" } },
            [
              _c("Alert", {
                attrs: { type: "warning", "show-icon": "", closable: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", [
                            _vm._v("提示：请先制定排班日历后再调整排班表"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1200859364
                ),
              }),
              _c("div", { staticClass: "schedule-heard" }, [
                _c(
                  "div",
                  { staticClass: "schedule-heard-left" },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "114px", "margin-right": "12px" },
                        attrs: { title: _vm.attendance },
                        on: { change: _vm.attendanceChange },
                        model: {
                          value: _vm.attendance,
                          callback: function ($$v) {
                            _vm.attendance = $$v
                          },
                          expression: "attendance",
                        },
                      },
                      _vm._l(_vm.attendanceGroups, function (item, index) {
                        return _c(
                          "a-select-option",
                          {
                            key: "selectText" + index,
                            staticClass: "selectText",
                            attrs: {
                              title: item.label,
                              label: item.label,
                              value: item.value,
                            },
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "time" },
                      [
                        _c("svg", { staticClass: "iconpark-icon" }, [
                          _c("use", { attrs: { href: "#rongqi" } }),
                        ]),
                        _vm.scheduleYears == "周"
                          ? _c("DateWeek", {
                              attrs: { format: "YYYY-MM-DD" },
                              on: { change: _vm.onChange },
                            })
                          : _c(
                              "a-month-picker",
                              {
                                attrs: { format: "YYYY-MM-DD" },
                                on: { change: _vm.onChange },
                              },
                              [
                                _c("span", { staticClass: "weekBox" }, [
                                  _vm._v(_vm._s(_vm.monthly)),
                                ]),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "unit" },
                    [
                      _c(
                        "a-radio-group",
                        {
                          on: { change: _vm.onSelect },
                          model: {
                            value: _vm.scheduleYears,
                            callback: function ($$v) {
                              _vm.scheduleYears = $$v
                            },
                            expression: "scheduleYears",
                          },
                        },
                        [
                          _c("a-radio-button", { attrs: { value: "周" } }, [
                            _vm._v(" 周 "),
                          ]),
                          _c("a-radio-button", { attrs: { value: "月" } }, [
                            _vm._v(" 月 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "appForm",
                {
                  attrs: {
                    "to-list": _vm.formList,
                    "search-placeholder": "请搜索姓名/工号",
                    "screen-roster-list": _vm.screenScheduleList,
                  },
                  on: {
                    getSearchCallback: _vm.getSearchCallback,
                    screenRosterParams: _vm.screenRosterParams,
                    exportFileList: _vm.exportFileList,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "customButton" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { prefix: "add-one" },
                          on: {
                            click: function ($event) {
                              _vm.showImport = true
                            },
                          },
                        },
                        [_vm._v("导入排班表")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.test
                ? _c("custom-table", {
                    ref: "table",
                    attrs: {
                      id: "table",
                      columns: _vm.columns,
                      "data-source": _vm.dataSource,
                      pagination: {
                        pageSize: 10,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showSizeChanger: true,
                        showTotal: _vm.dataSource.lenght,
                        showTotal: function (total) {
                          return "共 " + total + " 条数据"
                        },
                      },
                      "row-key": "index",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "id",
                          fn: function (ref) {
                            var scope = ref.scope
                            return [_vm._v(" " + _vm._s(scope.index + 1) + " ")]
                          },
                        },
                        {
                          key: "name",
                          fn: function (ref) {
                            var scope = ref.scope
                            return [
                              _c("CbTableAvater", {
                                attrs: {
                                  "table-name": scope.record.staffName,
                                  "on-job-id": scope.record.onJobId,
                                  "staff-id": scope.record.staffId,
                                  title: scope.record.staffName,
                                },
                              }),
                            ]
                          },
                        },
                        _vm._l(_vm.month, function (col) {
                          return {
                            key: col,
                            fn: function (ref) {
                              var scope = ref.scope
                              return [
                                _c("div", { key: col }, [
                                  _c("div", { staticClass: "btn-box" }, [
                                    scope.record[col].changeShiftFlag
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "a-popover",
                                              {
                                                staticClass: "popoverset",
                                                attrs: {
                                                  placement: "left",
                                                  title: "调班详情",
                                                  "arrow-point-at-center": true,
                                                  trigger: "click",
                                                },
                                                on: {
                                                  visibleChange: function (
                                                    $event
                                                  ) {
                                                    return _vm.transferDetails(
                                                      $event,
                                                      scope.record,
                                                      col
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "content" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "transfer-details beauty-scroll",
                                                      },
                                                      [
                                                        _c(
                                                          "a-timeline",
                                                          {
                                                            staticClass:
                                                              "holidayRecords-timeline",
                                                          },
                                                          _vm._l(
                                                            _vm.details,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "a-timeline-item",
                                                                { key: index },
                                                                [
                                                                  _c("a-row", [
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.changeShiftDescribe
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                  _c("a-row", [
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "调班原因: " +
                                                                          _vm._s(
                                                                            item.changeShiftReason
                                                                          )
                                                                      ),
                                                                    ]),
                                                                  ]),
                                                                  _c("a-row", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "operateType",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "operateType"
                                                                            )(
                                                                              item.operateType
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _c(
                                                    "svg",
                                                    { staticClass: "btn-icon" },
                                                    [
                                                      _c("use", {
                                                        attrs: {
                                                          href: "#cycle-one",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    scope.record[col].staffShiftId !== null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "sooner btn",
                                            style:
                                              "background:rgba(" +
                                              scope.record[col].backGroundRgba +
                                              ");color:rgba(" +
                                              scope.record[col].fontRgba +
                                              ")",
                                            attrs: {
                                              title:
                                                scope.record[col]
                                                  .shiftShortName,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.visibleChange(
                                                  scope.record,
                                                  col
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.record[col]
                                                    .shiftShortName
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "not btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.visibleChange(
                                                  scope.record,
                                                  col
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 无 ")]
                                        ),
                                  ]),
                                ]),
                              ]
                            },
                          }
                        }),
                        {
                          key: "operation",
                          fn: function (ref) {
                            var scope = ref.scope
                            return [
                              _c(
                                "a-popover",
                                {
                                  attrs: {
                                    placement: "left",
                                    trigger: "click",
                                  },
                                  on: {
                                    visibleChange: function ($event) {
                                      return _vm.overviewChange(
                                        $event,
                                        scope.record
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("template", { slot: "content" }, [
                                    _c("div", { staticClass: "overview" }, [
                                      _c(
                                        "div",
                                        { staticClass: "overview-head" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "name" },
                                            [
                                              _c("Avater", {
                                                attrs: {
                                                  "on-job-id":
                                                    scope.record.onJobId,
                                                  "avater-name":
                                                    scope.record.staffName,
                                                  size: "28",
                                                  "show-user-name": false,
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "staffnum" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.record.staffName
                                                    ) +
                                                      _vm._s(
                                                        scope.record.staffNum
                                                          ? "(" +
                                                              scope.record
                                                                .staffNum +
                                                              ")"
                                                          : ""
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.overview
                                                      .shiftSchedulingNames
                                                  )
                                                ),
                                              ]),
                                              _c("p", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "orangered",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.overview.planDays
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v("天"),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  "本" +
                                                    _vm._s(_vm.scheduleYears) +
                                                    "应出勤"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "overview-contrnt" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "content-span" },
                                            [_vm._v("班次统计")]
                                          ),
                                          _vm._l(
                                            _vm.overview.records,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "detailed-departures",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "sooner-btn sooner",
                                                      style: {
                                                        background:
                                                          "rgba(" +
                                                          item.backGroundRgba +
                                                          ")",
                                                        color:
                                                          "rgba(" +
                                                          item.fontRgba +
                                                          ")",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-radio-button",
                                                        {
                                                          staticClass: "sooner",
                                                          style: {
                                                            background:
                                                              "rgba(" +
                                                              item.fontRgba +
                                                              ")",
                                                          },
                                                          attrs: {
                                                            title:
                                                              item.shiftName,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.shiftName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.ruleTime)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("p", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "orangered",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.days)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v("天"),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ]),
                                  _c("span", { staticClass: "overview" }, [
                                    _vm._v("概述"),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("ExcelImport", {
            attrs: { title: "导入排班表", "import-template": "shift" },
            on: { toggleValue: _vm.toggleValue },
          }),
      _c("SetDrawer", {
        ref: "setDrawer",
        attrs: { "shift-list": _vm.shiftList },
        on: { change: _vm.updateStaffShift },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }