<!--
 * @Date: 2022-04-25 14:50:31
 * @descriotion:排班表
-->
<template>
  <div class="schedule">
    <div v-if="!showImport" id="Schedule">
      <Alert type="warning" show-icon closable>
        <template #content>
          <div>提示：请先制定排班日历后再调整排班表</div>
        </template>
      </Alert>
      <div class="schedule-heard">
        <div class="schedule-heard-left">
          <a-select
            v-model="attendance"
            style="width: 114px;margin-right:12px"
            :title="attendance"
            @change="attendanceChange"
          >
            <a-select-option
              v-for="(item,index) in attendanceGroups"
              :key="`selectText${index}`"
              class="selectText"
              :title="item.label"
              :label="item.label"
              :value="item.value"
            >{{ item.label }}</a-select-option>
          </a-select>
          <div class="time">
            <svg class="iconpark-icon"><use href="#rongqi" /></svg>
            <DateWeek v-if="scheduleYears=='周'" format="YYYY-MM-DD" @change="onChange" />
            <a-month-picker v-else format="YYYY-MM-DD" @change="onChange">
              <span class="weekBox">{{ monthly }}</span>
            </a-month-picker>
          </div>
        </div>
        <div>
          <div class="unit">
            <a-radio-group v-model="scheduleYears" @change="onSelect">
              <a-radio-button value="周">
                周
              </a-radio-button>
              <a-radio-button value="月">
                月
              </a-radio-button>
            </a-radio-group>
          </div>
          <!-- <a-select v-model="scheduleYears" style=" width: 58px;margin-right:12px" @select="onSelect">
            <a-select-option value="周">
              周
            </a-select-option>
            <a-select-option value="月">
              月
            </a-select-option>
          </a-select> -->
        </div>
      </div>
      <appForm
        :to-list="formList"
        search-placeholder="请搜索姓名/工号"
        :screen-roster-list="screenScheduleList"
        @getSearchCallback="getSearchCallback"
        @screenRosterParams="screenRosterParams"
        @exportFileList="exportFileList"
      >
        <template slot="customButton">
          <a-button prefix="add-one" @click="showImport = true">导入排班表</a-button>
        </template>
      </appForm>
      <custom-table
        v-if="test"
        id="table"
        ref="table"
        :columns="columns"
        :data-source="dataSource"
        :pagination="{
          pageSize: 10,
          pageSizeOptions:['10', '20', '50', '100'],
          showSizeChanger:true,
          showTotal:dataSource.lenght,
          showTotal: (total) => `共 ${total} 条数据`,
        }"
        row-key="index"
      >
        <!-- 序号 -->
        <template slot="id" slot-scope="{scope}">
          {{ scope.index+1 }}
        </template>
        <!-- 头像，姓名 -->
        <template slot="name" slot-scope="{ scope }">
          <CbTableAvater :table-name="scope.record.staffName" :on-job-id="scope.record.onJobId" :staff-id="scope.record.staffId" :title="scope.record.staffName" />
        </template>
        <template
          v-for="col in month"
          :slot="col"
          slot-scope="{scope}"
        >
          <div :key="col">
            <div class="btn-box">
              <div v-if="scope.record[col].changeShiftFlag">
                <a-popover placement="left" class="popoverset" title="调班详情" :arrow-point-at-center="true" trigger="click" @visibleChange="transferDetails($event,scope.record,col)">
                  <template slot="content">
                    <div class="transfer-details beauty-scroll">
                      <a-timeline class="holidayRecords-timeline">
                        <a-timeline-item v-for="(item,index) in details" :key="index">
                          <a-row>
                            <p>{{ item.changeShiftDescribe }}</p>
                          </a-row>
                          <a-row>
                            <p>调班原因: &nbsp;&nbsp;{{ item.changeShiftReason }}</p>
                          </a-row>
                          <a-row>
                            <div class="operateType">{{ item.operateType | operateType }}</div>
                          </a-row>
                        </a-timeline-item>
                      </a-timeline>
                    </div>
                  </template>
                  <div>
                    <svg class="btn-icon"><use href="#cycle-one" /></svg>
                  </div>
                  <!-- <a-icon v-if="scope.record[col].changeShiftFlag" type="eye" class="btn-icon" /> -->
                </a-popover>
              </div>
              <!-- <template>
                <a-popover placement="left" :arrow-point-at-center="true" trigger="click" @visibleChange="visibleChange($event,scope.record,col)">
                  <template slot="content">
                    <Popconfirm :shift-list="shiftList" @change="updateStaffShift" />
                  </template>
                  <div v-if="scope.record[col].staffShiftId!==null" :title="scope.record[col].shiftShortName" class="sooner btn" :style="`background:rgba(${scope.record[col].backGroundRgba});color:rgba(${scope.record[col].fontRgba})`">
                    {{ scope.record[col].shiftShortName }}
                  </div>
                  <div v-else class="not btn">
                    无
                  </div>
                </a-popover>
              </template> -->
              <div
                v-if="scope.record[col].staffShiftId!==null"
                :title="scope.record[col].shiftShortName"
                class="sooner btn"
                :style="`background:rgba(${scope.record[col].backGroundRgba});color:rgba(${scope.record[col].fontRgba})`"
                @click="visibleChange(scope.record,col)"
              >
                {{ scope.record[col].shiftShortName }}
              </div>
              <div v-else class="not btn" @click="visibleChange(scope.record,col)">
                无
              </div>
            </div>
          </div>
        </template>
        <template slot="operation" slot-scope="{scope}">
          <a-popover placement="left" trigger="click" @visibleChange="overviewChange($event,scope.record)">
            <template slot="content">
              <div class="overview">
                <div class="overview-head">
                  <div class="name">
                    <Avater :on-job-id="scope.record.onJobId" :avater-name="scope.record.staffName" size="28" :show-user-name="false" />
                    <!-- <img v-if="scope.record.avatar" :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${scope.record.onJobId}&width=${28}&height=${28}`" class="img_box">
                    <div v-else class="img_box">{{ avaterName }}</div> -->
                    <span class="staffnum">{{ scope.record.staffName }}{{ scope.record.staffNum ? `(${scope.record.staffNum})` : '' }}</span>
                  </div>
                  <div class="content">
                    <p>{{ overview.shiftSchedulingNames }}</p>
                    <p><span style="color:orangered">{{ overview.planDays }}</span>天</p>
                    <p>本{{ scheduleYears }}应出勤</p>
                  </div>
                </div>
                <div class="overview-contrnt">
                  <span class="content-span">班次统计</span>
                  <div v-for="(item,index) in overview.records" :key="index" class="detailed-departures">
                    <div class="sooner-btn sooner" :style="{background:`rgba(${item.backGroundRgba})`,color:`rgba(${item.fontRgba})`}">
                      <a-radio-button class="sooner" :title="item.shiftName" :style="{background:`rgba(${item.fontRgba})`}">{{ item.shiftName }}</a-radio-button>
                      <span>{{ item.ruleTime }}</span>
                    </div>
                    <p><span style="color:orangered">{{ item.days }}</span>天</p>
                  </div>
                </div>
              </div>
            </template>
            <span class="overview">概述</span>
          </a-popover>
        </template>
      </custom-table>

    </div>
    <ExcelImport v-else title="导入排班表" import-template="shift" @toggleValue="toggleValue" />
    <SetDrawer ref="setDrawer" :shift-list="shiftList" @change="updateStaffShift" />
  </div>
</template>
<script>
const time = [moment().startOf('week').format('YYYYMMDD'), moment().endOf('week').format('YYYYMMDD')]
const operateType = { '1': '调班审批', '2': 'HR添加' }
import mixins from '@/components/CustomTable/index.js'
import { getStaffShiftListPageApi, getShiftListApi, getStaffShiftGeneralViewApi, attendanceGroupNameListApi, exportStaffShiftListPageApi, updateStaffShiftApi, getStaffShiftChangeLogInfoApi } from '@/services/attendance/scheduling.js'
import moment from 'moment'
export default {
  name: 'Schedule',
  components: {
    Alert: () => import('@/components/CbAlert'),
    DateWeek: () => import('@/components/CbDateWeek'),
    CbTableAvater: () => import ('@/components/CbTableAvater/index.vue'),
    Avater: () => import ('@/components/CbAvater/theAvater.vue'),
    // Popconfirm: () => import('./popconfirm.vue'),
    ExcelImport: () => import('../components/excelImport.vue'),
    SetDrawer: () => import('./setDrawer.vue')
  },
  filters: {
    operateType(val) {
      return operateType[val]
    }
  },
  mixins: [mixins],
  data() {
    return {
      loading: true,
      showImport: false,
      form: {
        staffShiftId: '',
        shiftDate: '',
        onJobId: '',
        staffId: ''
      },
      staffName: '',
      details: [],
      attendance: '',
      visible: false,
      scheduleYears: '周',
      length: 2200,
      scheduleTime: time,
      overview: {},
      test: true,
      dataSource: [],
      formList: ['search', 'export', 'screen'],
      screenScheduleList: {
        'list': [
          {
            'type': 'selectPost',
            'label': '岗位选择',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择岗位',
              'showSearch': false
            },
            'model': 'postIds',
            'key': 'postIds',
            'rules': [
              {
                'required': false,
                'message': '请选择岗位'
              }
            ]
          },
          {
            'type': 'selectDepartment',
            'label': '部门选择',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'clearable': true,
              'placeholder': '请选择部门',
              'showSearch': false
            },
            'model': 'orgIds',
            'key': 'orgIds',
            'rules': [
              {
                'required': false,
                'message': '请选择部门'
              }
            ]
          },
          {
            'type': 'selectShift',
            'label': '班次选择',
            'options': {
              'multiple': true,
              'disabled': false,
              'width': '100%',
              'parameter': '',
              'clearable': true,
              'placeholder': '请选择班次',
              'showSearch': false,
              'defaultValue': []
            },
            'model': 'shiftId',
            'key': 'shiftId',
            'rules': [
              {
                'required': false,
                'message': '请选择班次'
              }
            ]
          }
        ],
        'config': {
          'layout': 'horizontal',
          'labelCol': {
            'xs': 4,
            'sm': 4,
            'md': 4,
            'lg': 4,
            'xl': 4,
            'xxl': 4
          },
          'labelWidth': 100,
          'labelLayout': 'flex',
          'wrapperCol': {
            'xs': 18,
            'sm': 18,
            'md': 18,
            'lg': 18,
            'xl': 18,
            'xxl': 18
          },
          'hideRequiredMark': false,
          'customStyle': ''
        }
      },
      attendanceGroup: '',
      attendanceGroups: [
        {
          value: '',
          label: '全部考勤组'
        }
      ],
      avaterName: '',
      monthly: time[0].substring(0, 4) + '-' + time[0].substring(4, 6),
      month: [],
      shiftList: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          key: 'id',
          scopedSlots: { customRender: 'id' },
          fixed: 'left',
          width: '50px'
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          fixed: 'left',
          scopedSlots: { customRender: 'name' },
          key: 'staffName',
          width: '120px'
        },
        {
          title: '岗位',
          dataIndex: 'onjobName',
          fixed: 'left',
          key: 'onjobName',
          width: '100px',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operate',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          key: 'operate',
          width: '50px'
        }
      ],
      upSchedule: {},
      time: null
    }
  },
  watch: {
    scheduleTime(newVal, oldVal) {
      if (newVal.toString() !== oldVal.toString()) { this.getStaffShiftListPage(this._queryParam) }
    }
  },
  created() {
    this.getShiftList()
    this.attendanceGroupNameList()
    this.getStaffShiftListPage()
  },
  mounted() { },
  methods: {
    // 点击班次时调用修改班次接口
    updateStaffShift(val, data) {
      this.staffShiftId = val
      updateStaffShiftApi({ shiftId: val, ...this.form }).then(res => {
        if (res.code !== 100) return
        this.$message.success('修改成功')
        this.form.staffShiftId = res.data.staffShiftId
        this.$nextTick(() => {
          if (val) {
            this.dataSource[this.upSchedule.index][this.upSchedule.key] = { ...this.upSchedule.key, ...res.data }
          } else {
            this.dataSource[this.upSchedule.index][this.upSchedule.key] = { staffShiftId: null }
          }
        })
      })
    },
    // 考勤组名称列表
    attendanceGroupNameList() {
      attendanceGroupNameListApi().then(res => {
        res.data.forEach(item => {
          item.value = item.attendanceGroupId
          item.label = item.attendanceGroupName
        })
        this.attendanceGroups.push(...res.data)
      })
    },
    // 班次列表
    getShiftList() {
      getShiftListApi().then(res => {
        if (res.code === 100) {
          this.shiftList = res.data
        }
      })
    },
    // 员工排班列表
    getStaffShiftListPage(data) {
      getStaffShiftListPageApi({ shiftDateStart: this.scheduleTime[0], shiftDateEnd: this.scheduleTime[1], attendanceGroupId: this.attendance, ...data }).then(res => {
        const arr = []
        const month = []
        res.data.header.forEach((item, index) => {
          const str = `${item.shiftDate}.shiftId`
          // console.log(str)
          const head = {
            title: `${item.shiftDateStr} ${item.dayWeekName}`,
            dataIndex: str,
            key: str,
            scopedSlots: { customRender: item.shiftDate },
            width: this.scheduleYears === '周' ? '' : '120px',
            ellipsis: true
          }
          month.push(item.shiftDate + '')
          // console.log(month)
          arr.push(head)
        })

        this.columns = [
          {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            scopedSlots: { customRender: 'id' },
            fixed: 'left',
            align: 'center',
            width: '50px'
          },
          {
            title: '姓名',
            dataIndex: 'staffName',
            fixed: 'left',
            scopedSlots: { customRender: 'name' },
            key: 'staffName',
            width: '120px'
          },
          {
            title: '岗位',
            dataIndex: 'onjobName',
            fixed: 'left',
            key: 'onjobName',
            width: '100px',
            ellipsis: true
          },
          {
            title: '操作',
            dataIndex: 'operate',
            fixed: 'right',
            scopedSlots: { customRender: 'operation' },
            key: 'operate',
            width: '50px'
          }
        ]
        this.columns.splice(3, 0, ...arr)
        // this.columns.push({})
        this.dataSource = res.data.page
        this.dataSource.forEach((item, index) => {
          item.index = index
        })
        this.$set(this, 'columns', this.columns)
        this.test = false
        this.$set(this, 'month', month)
        this.$nextTick(() => {
          this.test = true
          // this.setDataSource(res.data.page)
        })
        console.log(this.columns, this.dataSource)
      }).catch(() => {
        this.dataSource = []
        this.columns = [
          {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            scopedSlots: { customRender: 'id' },
            fixed: 'left',
            width: '50px'
          },
          {
            title: '姓名',
            dataIndex: 'staffName',
            fixed: 'left',
            scopedSlots: { customRender: 'name' },
            key: 'staffName',
            width: '120px'
          },
          {
            title: '岗位',
            dataIndex: 'postName',
            fixed: 'left',
            key: 'postName',
            width: '100px'
          },
          {
            title: '操作',
            dataIndex: 'operate',
            fixed: 'right',
            scopedSlots: { customRender: 'operation' },
            key: 'operate',
            width: '77px'
          }
        ]
      })
    },
    // 动态插入数据
    setDataSource(data) {
      this.dataList = data
      this.dataSource = this.dataList.slice(0, this.tableDataNum)
      const dom = document.querySelector('.ant-table-scroll > .ant-table-body')
      dom.addEventListener('scroll', this.debounce(this.loadData, 100))
    },
    loadData() {
      const dom = document.querySelector('.ant-table-scroll > .ant-table-body')
      const scrolldistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
      console.log(scrolldistance, dom.scrollHeight, dom.scrollTop, dom.clientHeight)
      const scrollTop = dom.scrollTop
      if (parseInt(scrolldistance) < 300) {
        if (this.dataList.lenght === this.tableDataNum || this.dataSource.length < 40) {
          return
        }
        this.tableLoading = true
        this.dataSource.push(...this.dataList.slice(this.tableDataNum, this.tableDataNum + 10))
        this.dataSource.splice(0, 10)
        this.tableDataNum += 10
        console.log(this.dataSource.length)
        this.$nextTick(() => {
          dom.scrollTop = scrollTop - 300
          setTimeout(() => {
            this.tableLoading = false
          }, 1000)
        })
      }
      if (dom.scrollTop < 300) {
        if (this.tableDataNum === 40) return
        this.tableLoading = true
        this.dataSource.unshift(...this.dataList.slice(this.tableDataNum - 50, this.tableDataNum - 40))
        this.tableDataNum -= 10
        this.dataSource.splice(40, 10)
        console.log(this.dataSource.length)
        this.$nextTick(() => {
          dom.scrollTop = scrollTop + 300
          setTimeout(() => {
            this.tableLoading = false
          }, 1000)
        })
      }
    },
    debounce(fn, wait = 1000) {
      var timer
      return function() {
        const that = this
        clearTimeout(timer)
        timer = setTimeout(() => {
          fn.apply(that)
        }, wait)
      }
    },
    // 查询回调
    getSearchCallback(data) {
      this._queryParam.searchWord = data.searchValue
      this.getStaffShiftListPage(this._queryParam)
    },
    // 筛选回调
    screenRosterParams(data) {
      console.log(data)
      this._queryParam.searchWord = data.searchValue
      const orgIds = []
      this._queryParam.orgIds = null
      this._queryParam.postIds = null
      this._queryParam.shiftId = null
      if (data.screenCondition.orgIds !== undefined) {
        data.screenCondition.orgIds.forEach(item => {
          orgIds.push(item.value)
        })
        this._queryParam.orgIds = orgIds.join(',')
      }
      if (data.screenCondition.postIds !== undefined) {
        this._queryParam.postIds = data.screenCondition.postIds.join(',')
      }
      if (data.screenCondition.shiftId !== undefined) {
        this._queryParam.shiftId = data.screenCondition.shiftId.join(',')
      }
      this.getStaffShiftListPage(this._queryParam)
    },
    // 导出
    exportFileList() {
      exportStaffShiftListPageApi({
        shiftDateStart: this.scheduleTime[0],
        shiftDateEnd: this.scheduleTime[1],
        searchWord: this._queryParam.searchWord,
        orgIds: this._queryParam.orgIds,
        postIds: this._queryParam.postIds,
        shiftId: this._queryParam.shiftId,
        attendanceGroupId: this.attendance })
    },
    // 时间框数据发生变化时的回调
    onChange(date, dateString) {
      if (this.scheduleYears === '周') {
        this.scheduleTime = date
      } else {
        this.scheduleTime = [moment(dateString).startOf('month').format('YYYYMMDD'), moment(dateString).endOf('month').format('YYYYMMDD')]
        this.monthly = dateString.substring(0, 7)
      }
      this.scheduleTime = [this.scheduleTime[0].split('-').join(''), this.scheduleTime[1].split('-').join('')]
      // this.getStaffShiftListPage(this._queryParam)
    },
    // 气泡确认框显示回调
    visibleChange(data, val) {
      this.form = {}
      const now = moment(new Date()).format('YYYYMMDD')
      const date = val.slice(3)
      if (Number(date) <= Number(now)) {
        this.$message.warning('历史日期的排班, 无法修改班次')
        return
      }
      // 显示气泡卡片给员工调班ID赋值
      this.upSchedule = { ...data, key: val }
      this.$nextTick(() => {
        this.form.shiftDate = val.substring('3')
        this.form.onJobId = data.onJobId
        this.form.staffId = data.staffId
        this.form.attendanceGroupId = data.attendanceGroupId
        this.form.staffShiftId = data[val].staffShiftId
        console.log('staffShiftId', this.form.staffShiftId)
        this.$refs.setDrawer.showDrawer()
      })
    },
    // 概览气泡卡片
    overviewChange(key, data) {
      console.log(data)
      if (data.staffName) {
        const arr = data.staffName.split('')
        this.avaterName = arr[arr.length - 2] + arr[arr.length - 1]
      }
      if (key === true) {
        this.staffName = data.staffName
        getStaffShiftGeneralViewApi({ onJobId: data.onJobId, shiftDateStart: this.scheduleTime[0], shiftDateEnd: this.scheduleTime[1] }).then(res => {
          if (res.code === 100) {
            this.overview = res.data
          }
        })
      }
    },
    onSelect() {
      if (this.scheduleYears === '周') {
        this.scheduleTime = [moment().startOf('week').format('YYYYMMDD'), moment().endOf('week').format('YYYYMMDD')]
      } else {
        this.scheduleTime = [moment().startOf('month').format('YYYYMMDD'), moment().endOf('month').format('YYYYMMDD')]
        this.monthly = this.scheduleTime[0].substring(0, 4) + '-' + this.scheduleTime[0].substring(4, 6)
      }
      // this.getStaffShiftListPage(this._queryParam)
    },
    attendanceChange() {
      this.getStaffShiftListPage(this._queryParam)
    },
    transferDetails(key, val, col) {
      if (key === true) {
        const shiftDate = col.substr(3, 8)
        const onJobId = val[col].onJobId
        getStaffShiftChangeLogInfoApi({ shiftDate, onJobId }).then(res => {
          this.details = res.data
        })
      }
    },
    toggleValue(val) {
      this.showImport = val
      this.getStaffShiftListPage(this._queryParam)
    }
  }
}
</script>
<style lang="less" scoped>
.schedule{
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  background-color: @base-bg-color;
  #Schedule{
    height: 100%;
  }
}
.schedule-heard{
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.overview{
  width: 300px;
  height:auto;
  .overview-head{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid @sc-greyBg-10;
    p{
      color: @sc-grey-60;
    }
    .name{
      width: auto !important;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      span{
        color: @sc-grey-80;
      }
      .staffnum{
        margin: 0 8px;
        line-height: 28px;
      }
    }
    .content{
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      p{
        width: 100%;
        text-align: right;
        margin: 0;
        &:nth-child(1){
          font-weight: 600;
        }
        &:nth-child(3){
          font-size: 12px;
        }
      }
    }
  }
  .overview-contrnt{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    p{
      color: @sc-grey-60;
    }
    span{
      font-size: 16px;
      width: 100%;
    }
    .content-span{
      color: @sc-grey-80;
      padding:  0 0 0 10px;
      height: 15px;
      line-height: 15px;
      margin: 10px 0 0;
      border-left: 4px solid @sc-primary-100;
    }
    .detailed-departures{
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      span{
        width: auto;
        font-size: 14px;
      }
      p{
        margin: 0;
        display: flex;
        align-items: center;
      }
      .sooner-btn{
        width: 190px;
        height: 26px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        .sooner{
          color: @base-bg-color;
          font-size: 14px;
          width: 95px;
          height: 26px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 26px;
        }
        .sublevel{
          color: @base-bg-color;
          height: 26px;
          line-height: 26px;
        }
        .rest{
          color: @base-bg-color;
          line-height: 26px;
          height: 26px;
        }
        span{
          margin-left: 8px;
        }
}
    }
  }
}
.ant-popover-message-title{
  padding: 0;
}
/deep/.ant-popover-message{
  /deep/.anticon-exclamation-circle{
    display: none;
  }
}
.btn-box{
  width: 95px;
  height: 30px;
  position: relative;
  cursor: pointer;
  .btn-icon{
      position: absolute;
      top: 0;
      z-index: 1;
      right: 0;
      width: 14px;
      height: 14px;
      color: @sc-primary-100;
  }

}
.btn{
    width: 78px;
    height: 24px;
    border-radius: 4px;
    text-align: center;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    margin: 4px 4px 0 0;
}
.not{
  color: @sc-grey-40;
  background: @sc-greyBg-10;
  height: 26px;
  line-height: 26px;
}
.schedule-heard-left{
  display: flex;
  /deep/.ant-select{
    color: @sc-grey-100;
    &:hover{
    border-color: @sc-primary-100;
    color: @sc-primary-100;
  }
  }
  /deep/.ant-input{
    width: 200px;
  }
  /deep/.weekBox{
    width:auto;
    font-size: 14px;
    padding:  4px 10px;
    height: auto;
    border: 0;
    color: @sc-grey-100;;
    background: @sc-greyBg-10;
    &:hover {
      color: @sc-primary-100;
      cursor: pointer;
    }
  }
}
.overview{
  color:@sc-primary-100;
  &:hover{
    cursor: pointer;
  }
}
.transfer-details{
  padding:10px 0;
   height: auto;
   max-height: 400px;
   overflow: auto;
   width: 300px;
  .operateType{
    width: 30%;
    padding: 0 10px;
    border-radius: 2px;
    text-align: center;
    height: auto;
    background: @sc-greyBg-10;
  }
}
.time {
  height: 34px !important;
  border: 1px solid @sc-greyBg-10;
  display: flex;
  align-items: center;
  background: @sc-greyBg-10;
  padding-left: 10px;
  border-radius: 4px;
  &:hover .weekBox {
    color: @sc-primary-100;
    cursor: pointer;
  }
  .iconpark-icon{
    width: 20px;
    height: 20px;
    color: @sc-primary-100;
  }
}
/deep/ .ant-alert.ant-alert-closable {
  border-radius: 4px;
  margin-bottom: 16px;
}
</style>
